.gm-style-iw.gm-style-iw-c {
    padding: 0;
  
}

.gm-style-iw.gm-style-iw-c > .gm-style-iw-d {
    overflow: hidden !important;
  }

.gm-style .gm-style-iw-tc {
    display: none;
}